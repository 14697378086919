import React from "react"
import NavigationBar from "../components/Navbar"
import { Helmet } from "react-helmet"

export default class BlogIndexPage extends React.Component {
  render() {
    return (
      <div className="justify-center mx-auto rounded-lg">
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <meta charSet="utf-8" />
          <title>404 - Not Found</title>
          <meta name="description" content="404 - Not Found" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        </Helmet>
        <NavigationBar />
        <h1 className="text-6xl font-bold prose text-center my-44">
          404 Page Not Found
        </h1>
      </div>
    )
  }
}
